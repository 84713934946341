<template>
  <div class="btn">
    <div class="btnNav">
      <div class="btnNavTittle">{{title}}</div>
      <div class="appList">
        <div class="appItem" v-for="item in pubilcArr" :key="item.id" @click="onlist(item)">
          <img v-lazy="item.columnIcon" alt="">
          <div>{{item.columnName}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"btn",
  props:["pubilcArr"],
  data() {
    return {
      title:''  
    }
  },
  computed:{
    topTittle(){
      return this.$store.state.topTittle
    },
  },
  watch:{
    pubilcArr(){
      this.pubilcArr = this.pubilcArr
    }
  },
  created(){
    // this.title = localStorage.getItem('btnTittle')
  },
  mounted(){
    if(this.$store.state.jxbApp){
      window.location.href=`ssptitle://${this.topTittle}`
    }else{
      document.title = this.topTittle
    }
     
  },
  methods:{
    async onlist(item){
      if(item.columnType == 3){
        location.href = item.columnLink
          // lightAppJssdk.weex.openWeexVc({
          //   title :item.columnName,//顶部导航栏标题
          //   url : item.columnLink, //weex页面地址
          //   success: function(data) {
          //       console.log(data);
          //   },
          //   fail: function(data) {
          //       console.log('falied');
          //   }
          //   });
        }else{
           this.$store.commit('setTopTittle',item.columnName)
            // 首页数据
            this.$store.commit('setMenulist',item)
            // 传递id
            this.$router.push({path:'/chooseList',query:{categoryId:item.id,columnType:item.columnType,columnAttr:item.columnAttr,columnStyle:item.columnStyle}})
        }
    }
  },
  watch:{
  }
}
</script>
<style lang="less" scoped>
.btn{
  .btnNav{
    padding:0 .249rem;
    box-sizing: border-box;
    margin-bottom: .587rem;
    .btnNavTittle{
      margin-top: .631rem;
      margin-left: .533rem;
      font-size: .48rem;
      color: #000;
      font-weight: 600;
      text-align: left;
    }
    .appList{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: space-between;
    }
    .appItem{
      width: 2.3rem;
      display: flex;
      flex-direction: column;
      margin-top: .72rem;
      text-align: center;
      img{
        width: .889rem;
        height: .889rem;
        margin: 0 auto;
      }
      div{
        color: #333;
        font-size: .34667rem;
      }
    }
  }
  .btnNav:nth-child(1){
    // margin-top: .871rem;
  }
}
</style>