<template>
  <div class="tab">
    <van-tabs v-model="activeTab" title-active-color="#4b83ef" color="#4b83ef" @click="ontab(activeTab)">
      <van-tab v-for="(item,index) in pubilcArr" :key="item.id" :title="item.columnName">
        <div v-if="activeTab == index">
          <tabstyle></tabstyle>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import tabstyle from '@/views/tabstyle/sccontent.vue'
import { getServiceContentByColumnId } from "@/api/home.js";
export default {
  props:["pubilcArr"],
  name:"tab",
  data() {
    return {
      types:1,
      // 查询
      searchValue:'',
      // tab栏默认
      activeTab:0,
      // 内容形式id
      contentId:'',
      // 内容url
      contentType:'',
      // tab栏控制嵌入页面
      tabEmbedded:1,
      tabList:{},
      listArr:[]
    }
  },
  components:{
    tabstyle
  },
  computed:{
    topTittle(){
      return this.$store.state.topTittle
    },
    classification(){
      return this.$store.state.classification
    },
    menuType(){
      return this.$store.state.menuType
    },
  },
  created(){
    this.ontab()
  },
  mounted(){
    if(this.$store.state.jxbApp){
      window.location.href=`ssptitle://${this.topTittle}`
    }else{
      document.title = this.topTittle
    }
  },
  watch:{
    // pubilcArr:{
    //   handler(newdata,old){
    //     if(this.pubilcArr){
    //       this.pubilcArr = this.pubilcArr
    //       this.ontab()
    //     }
    //   },
    //   deep:true,
    //   immediate:true
    // }
  },
  methods:{
    onSearch(){

    },
    init(){
      
    },
    async ontab(){
      this.$toast.loading({
        message: '加载中',
        duration: 0, // 会一直存在,如果有下一个this.$toast被调用，它就会被覆盖，也就消失了
        mask: true
      });
      // console.log(this.pubilcArr)
      let list = this.pubilcArr[this.activeTab]
      if(list){
        this.$store.commit('settabTtpe',list)
      }
      if(list.columnType == 1){
        this.$store.commit('settablistType',3)
      }else{
        if(list.columnStyle == 2){
          this.$store.commit('settablistType',1)
        }else if(list.columnStyle == 3){
          this.$store.commit('settablistType',2)
        }
      }
      this.$toast.clear();
    }
  }
}
</script>

<style lang="less" scoped>
.tab{
  // margin-top: 72px;
  // position: fixed;
  // top: 0;
  // z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/deep/.van-tabs--line .van-tabs__wrap{
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}
/deep/.van-tabs{
  // height: 100%;
}
/deep/.van-tabs__content{
  // height: 100%;
}
// /deep/.van-search--show-action{
//   position: fixed;
//   top: 0;
//   z-index: 999;
//   width: 100%;
// }
</style>