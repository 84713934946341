<template>
  <div class="nkopne">
    <div v-if="aa || nkpdfUrl || src || fromlist || contentList1">
      <!-- <div class="contentTittle" v-if="fromType == 1">{{tittle}}</div>
      <div class="time" v-if="fromType == 1">{{time}}</div> -->
      <!-- 文本 -->
      <div class="text" v-html="aa" v-if="fromType == 1"></div>
      <div class="attachment" v-if="fromType == 1 && fileList.length>0" >
          附件下载：
        <div class="filedName" v-for="(item,index) in fileList" :key="index" @click="download(item)">
            <a>{{item.name}}</a>
        </div>
      </div>
      <!-- pdf -->
      <pdf v-else-if="fromType == 2" :nkpdfUrl="nkpdfUrl"></pdf>
      <!-- 嵌入外联 -->
      <iframe v-else-if="fromType == 3" :src="src" frameborder="0" ref="refed" width="100%" height="100%" ></iframe>
      <!-- 表单 -->
      <fromlist v-else-if="fromType == 4" :fromlist="fromlist" :fromTittle="fromTittle"></fromlist>
      <!-- 列表 -->
      <fromtext v-else-if="fromType == 5" :contentList1="contentList1"></fromtext>
    </div>
    <div v-else>
      <nodata></nodata>
    </div>
  </div>
</template>
<script>
import { getContentDetail,getContentByColumnId,getServiceContentByColumnId,getServiceContentDetail,downloadFile } from '@/api/home.js';
import pdf from '@/components/pdf/pdf.vue'
import fromlist from '@/components/contentlist/fromlist.vue'
import fromtext from '@/components/contentlist/fromtext.vue'
export default {
  name:"nkopne",
  data() {
    return {
      aa:'',
      src:'http://www.baidu.com',
      nkpdfUrl:'',
      fromlist:null,
      fromType:1,
      contentList1:[],
      tittle:'',
      time:'',
      fromTittle:'',
      fileList:[]
    }
  },
  computed:{
    contentType(){
      return this.$store.state.type
    },
    contentId(){
      return this.$store.state.tabId
    },
    menuType(){
      return this.$store.state.menuType
    }
  },
  components:{
    pdf,
    fromlist,
    fromtext
  },
  mounted(){},
  watch:{
    contentId:{
      handler(){
        if(this.contentId){
          this.init()
        }
      },
      immediate: true
    }
  },
  created(){
    // this.init()
  },
  methods:{
    async download(item){
            this.$toast.loading({
              message: '加载中',
              duration: 0, // 会一直存在,如果有下一个this.$toast被调用，它就会被覆盖，也就消失了
              mask: true
            });
                let link = document.createElement('a')
                link.style.display = 'none'
                let url = item.url//绝对地址
                link.href = url
                link.download = item.name//文件名
                document.head.appendChild(link)
                link.click()
                document.head.removeChild(link)
                this.$toast.clear();
        },
    async init(){
      if(this.contentType == 5){
        if(this.menuType == 'lanmu'){
          this.fromType = 5
          let data = await getContentByColumnId({
            "columnId":this.contentId,
            "pageNumber": 1,
            "pageSize": 10
          })
          let arr = data.data.data.rows
          arr.map((item)=>{
            if(item.tagInfo){
              item.tagInfo = item.tagInfo.split(',')
            }
          })
          this.contentList1 = arr
        }else{
          this.fromType = 5
          let data = await getServiceContentByColumnId({
            "columnId":this.contentId,
            "pageNumber": 1,
            "pageSize": 10
          })
          let arr = data.data.data.rows

          arr.map((item)=>{
            if(item.tagInfo){
              item.tagInfo = item.tagInfo.split(',')
            }
          })
          this.contentList1 = arr
        }
        
      }else{
        // 
        if(this.menuType == 'lanmu'){
          let data = await getContentDetail(this.contentId)
          let datacontent = data.data.data
          if(datacontent.detailType == 1){
            if(datacontent.type == 1){
              this.fromType = 1
              this.tittle = datacontent.title
              this.time = datacontent.publishTime
              let a = datacontent.content.replace(/<img/g, '<img style="max-width: 100%;"')
              // this.aa = a.replace(/<p/g, '<p style="font-size:16px;"')
              this.aa = '<div style="font-size:16px;">'+a+'</div>'
              // this.aa = datacontent.content
              if(datacontent.fileId || datacontent.fileName){
                        let fileUrl = datacontent.fileId.split(',')
                        let fileName = datacontent.fileName.split(',')
                        for(let i in fileUrl){
                            let obj = {
                                url:fileUrl[i],
                                name:fileName[i]
                            }
                            this.fileList.push(obj)
                        }
                    }
            }else if(datacontent.type == 2){
              this.fromType = 2
              this.nkpdfUrl = datacontent.pdfFileId
            }else{
              this.fromType = 3
              this.src = datacontent.outUrl
            }
          }else{
            this.fromType = 4
            this.fromTittle = datacontent.title
            this.fromlist = datacontent.formInfo
          }
        }else{
          let data = await getServiceContentDetail(this.contentId)
          let datacontent = data.data.data
          if(datacontent.detailType == 1){
            if(datacontent.type == 1){
              this.fromType = 1
              // this.aa = datacontent.content
              this.tittle = datacontent.title
              this.time = datacontent.publishTime
              let a = datacontent.content.replace(/<img/g, '<img style="max-width: 100%;"')
              this.aa = a.replace(/<p/g, '<p style="font-size:16px;"')
              if(datacontent.fileId || datacontent.fileName){
                let fileUrl = datacontent.fileId.split(',')
                let fileName = datacontent.fileName.split(',')
                for(let i in fileUrl){
                    let obj = {
                        url:fileUrl[i],
                        name:fileName[i]
                    }
                    this.fileList.push(obj)
                }
              }
            }else if(datacontent.type == 2){
              this.fromType = 2
              this.nkpdfUrl = datacontent.pdfFileId
            }else{
              this.fromType = 3
              this.src = datacontent.outUrl
            }
          }else{
            this.fromType = 4
            this.fromTittle = datacontent.title
            this.fromlist = datacontent.formInfo
          }
        }
        
      }
      
    }
  }
}
</script>
<style lang="less" scope>
// @import './nkopen.less';
.nkopne{
  .text{
    padding: 0 .542rem;
    box-sizing: border-box;
    margin-top: 1.7rem;
  }
  .attachment{
    font-size: 0.433rem;
    padding: 0 0.542rem;

    .filedName{
        color: #0000d9;
        margin: .178rem 0;
        cursor: pointer;
    }
  }
  .contentTittle{
      margin-top: .622rem;
      font-size: .533rem;
      text-align: left;
      color: #333333;
      font-weight: 600;
      margin-left: .542rem;
  }
  .time{
      margin-top: .267rem;
      color: #838282;
      font-size: .356rem;
      margin-left: .542rem;
  }
}
</style>